<script>
import TablePage from '@/found/components/table_page';

import Form from './form';

export default {
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestType: 'GET',
    };
  },
  mounted() {
    this.getConfigList('group_buying_Ex_warehouse_list');
  },
  methods: {
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'particulars') {
        this.formConfig = {
          row,
          type: code === 'particulars' ? 'view' : code,
          title: val.name,
        };
        this.openFull();
      }
    },
  },
};
</script>
