<template>
  <div>
    <div v-for="(item,index) in formData"
         :key="index">
      <el-row>
        <el-col :span="4">
          <el-form-item label="每">
            <el-select class="width80"
                       :disabled="disabled"
                       placeholder="奖励"
                       v-model="item.barCodeType"
                       @change="changFormData(index,'unit')">
              <el-option class="width80"
                         v-for="i in barOptions"
                         :key="i.value"
                         :label="i.label"
                         :value="i.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item label="奖励经销商">
            <div class="flex1">
              <el-select class="margin_r6"
                         :disabled="disabled"
                         v-model="item.rewardFlag"
                         placeholder="奖励类型"
                         @change="changFormData(index,'reward')">
                <el-option v-for="i in rewardOptions"
                           :key="i.value"
                           :label="i.label"
                           :value="i.value">
                </el-option>
              </el-select>
              <el-select class="margin_r6"
                         :disabled="disabled"
                         v-model="item.awardFlag"
                         placeholder="奖励条件"
                         @change="changFormData(index,'award')">
                <el-option v-for="i in awardOptions"
                           :key="i.value"
                           :label="i.label"
                           :value="i.value">
                </el-option>
              </el-select>
              <div class="flex1"
                   v-if="item.awardFlag === 'fixedAmount'">
                <el-input :disabled="disabled"
                          class="margin_r2 width60"
                          v-model="item.fixedAmount"
                          placeholder="金额"
                          @blur="inputFormData"></el-input>
                <span>元</span>
                <!-- <a v-if="index === 0  && !disabled"
                 class="el-icon-circle-plus-outline rowLineIcon"
                 @click="() => addRate(index)"></a> -->
                <!-- <a v-if="!disabled"
                 class="el-icon-remove-outline rowLineIcon closeIcon"
                 @click="() => delRate(index)"></a> -->
              </div>
            </div>
          </el-form-item>

        </el-col>
      </el-row>
      <div v-if="item.awardFlag === 'randomAmount'">
        <el-row class="marT10"
                v-for="(i,ind) in item.randomAmount"
                :key="ind">
          <el-col :span="4"></el-col>
          <el-col :span="18">
            <el-form-item label="定额">
              <div class="flex1">
                <div class="flex1 margin_r6">
                  <el-input :disabled="disabled"
                            class="margin_r2 width60"
                            v-model="i.cash"
                            placeholder="金额"
                            @blur="inputFormData"
                            @input="checkNum"></el-input>
                  <span>元</span>
                </div>
                <div class="flex1">
                  <el-input :disabled="disabled"
                            class="margin_r2 width60"
                            v-model="i.percent"
                            placeholder="比例"
                            @blur="inputFormData"></el-input>
                  <span>%</span>
                </div>
                <a v-if="ind === 0 && !disabled"
                   class="el-icon-circle-plus-outline rowLineIcon"
                   @click="() => addRate(index)"></a>
                <a v-else-if="ind !== 0 && !disabled"
                   class="el-icon-remove-outline rowLineIcon closeIcon"
                   @click="() => delRate(index,ind)"></a>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

    </div>

  </div>
</template>
<script>

export default {
  data() {
    return {
      barOptions: [
        {
          value: '1',
          label: '箱码',
        },
      ],
      rewardOptions: [
        {
          value: 'cost',
          label: '费用',
        },
        {
          value: 'cash',
          label: '现金权益',
        },
      ],
      awardOptions: [
        {
          value: 'randomAmount',
          label: '定额随机',
        },
        {
          value: 'fixedAmount',
          label: '固定额度',
        },
      ],
      formData: [
        {
          participatorFlag: '1',
          participatorName: '经销商出库',
          awardFlag: '',
          awardName: '',
          barCodeType: '',
          rewardName: '',
          rewardFlag: '',
          fixedAmount: '',
          randomAmount: [{ cash: '', percent: '' }],
        },
      ],
    };
  },
  props: {
    value: Array,
    disabled: Boolean,
  },
  watch: {
    value(val) {
      this.formData = val.map((item) => {
        let randomAmount;
        let fixedAmount;
        if (item.awardFlag === 'randomAmount') {
          console.log(item.rewardData);
          randomAmount = item.rewardData ? JSON.parse(item.rewardData || "[{cash: '', percent: ''}]") : [{ cash: '', percent: '' }];
          fixedAmount = '';
          console.log(randomAmount);
        } else if (item.awardFlag === 'fixedAmount') {
          fixedAmount = item.rewardData || '';
          randomAmount = [{ cash: '', percent: '' }];
          console.log(fixedAmount);
        }
        return {
          ...item,
          randomAmount,
          fixedAmount,
        };
      });
      // this.inputFormData();
    },
  },
  methods: {
    changFormData(index, type) {
      if (type === 'reward') {
        const option = this.rewardOptions.find(
          (item) => item.value === this.formData[index].rewardFlag,
        );
        this.formData[index].rewardName = option?.label || '';
      } else if (type === 'award') {
        const option = this.awardOptions.find(
          (item) => item.value === this.formData[index].awardFlag,
        );
        this.formData[index].awardName = option?.label || '';
      }
      this.inputFormData();
    },
    inputFormData() {
      this.formData = this.formData.map((i) => {
        // console.log(i.randomAmount);
        if (i.awardFlag === 'randomAmount') {
          i.rewardData = JSON.stringify(i.randomAmount);
        } else {
          i.rewardData = i.fixedAmount;
        }
        return i;
      });
      this.$emit('input', this.formData);
    },
    addRate(index) {
      this.formData[index].randomAmount.push({
        cash: '', percent: '',
      });
    },
    delRate(index, ind) {
      this.formData[index].randomAmount.splice(ind, 1);
    },
    checkNum(e) {
      console.log(e.target.value);
    },
  },
};
</script>
<style scoped>
.flex1 {
  display: flex;
}
.width60 {
  width: 60px !important;
}
.width80 {
  width: 80px !important;
}
.margin_r6 {
  margin-right: 6px;
}
.margin_r2 {
  margin-right: 2px;
}
.rowLineIcon {
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  padding-left: 10px;
}
.closeIcon {
  color: #f56c6c !important;
}
.marT10 {
  /* margin-top: 10px; */
}
</style>
