var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.formData, function (item, index) {
      return _c(
        "div",
        { key: index },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "每" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width80",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "奖励",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changFormData(index, "unit")
                            },
                          },
                          model: {
                            value: item.barCodeType,
                            callback: function ($$v) {
                              _vm.$set(item, "barCodeType", $$v)
                            },
                            expression: "item.barCodeType",
                          },
                        },
                        _vm._l(_vm.barOptions, function (i) {
                          return _c("el-option", {
                            key: i.value,
                            staticClass: "width80",
                            attrs: { label: i.label, value: i.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c("el-form-item", { attrs: { label: "奖励经销商" } }, [
                    _c(
                      "div",
                      { staticClass: "flex1" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "margin_r6",
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "奖励类型",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changFormData(index, "reward")
                              },
                            },
                            model: {
                              value: item.rewardFlag,
                              callback: function ($$v) {
                                _vm.$set(item, "rewardFlag", $$v)
                              },
                              expression: "item.rewardFlag",
                            },
                          },
                          _vm._l(_vm.rewardOptions, function (i) {
                            return _c("el-option", {
                              key: i.value,
                              attrs: { label: i.label, value: i.value },
                            })
                          }),
                          1
                        ),
                        _c(
                          "el-select",
                          {
                            staticClass: "margin_r6",
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "奖励条件",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.changFormData(index, "award")
                              },
                            },
                            model: {
                              value: item.awardFlag,
                              callback: function ($$v) {
                                _vm.$set(item, "awardFlag", $$v)
                              },
                              expression: "item.awardFlag",
                            },
                          },
                          _vm._l(_vm.awardOptions, function (i) {
                            return _c("el-option", {
                              key: i.value,
                              attrs: { label: i.label, value: i.value },
                            })
                          }),
                          1
                        ),
                        item.awardFlag === "fixedAmount"
                          ? _c(
                              "div",
                              { staticClass: "flex1" },
                              [
                                _c("el-input", {
                                  staticClass: "margin_r2 width60",
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: "金额",
                                  },
                                  on: { blur: _vm.inputFormData },
                                  model: {
                                    value: item.fixedAmount,
                                    callback: function ($$v) {
                                      _vm.$set(item, "fixedAmount", $$v)
                                    },
                                    expression: "item.fixedAmount",
                                  },
                                }),
                                _c("span", [_vm._v("元")]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          item.awardFlag === "randomAmount"
            ? _c(
                "div",
                _vm._l(item.randomAmount, function (i, ind) {
                  return _c(
                    "el-row",
                    { key: ind, staticClass: "marT10" },
                    [
                      _c("el-col", { attrs: { span: 4 } }),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c("el-form-item", { attrs: { label: "定额" } }, [
                            _c("div", { staticClass: "flex1" }, [
                              _c(
                                "div",
                                { staticClass: "flex1 margin_r6" },
                                [
                                  _c("el-input", {
                                    staticClass: "margin_r2 width60",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      placeholder: "金额",
                                    },
                                    on: {
                                      blur: _vm.inputFormData,
                                      input: _vm.checkNum,
                                    },
                                    model: {
                                      value: i.cash,
                                      callback: function ($$v) {
                                        _vm.$set(i, "cash", $$v)
                                      },
                                      expression: "i.cash",
                                    },
                                  }),
                                  _c("span", [_vm._v("元")]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "flex1" },
                                [
                                  _c("el-input", {
                                    staticClass: "margin_r2 width60",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      placeholder: "比例",
                                    },
                                    on: { blur: _vm.inputFormData },
                                    model: {
                                      value: i.percent,
                                      callback: function ($$v) {
                                        _vm.$set(i, "percent", $$v)
                                      },
                                      expression: "i.percent",
                                    },
                                  }),
                                  _c("span", [_vm._v("%")]),
                                ],
                                1
                              ),
                              ind === 0 && !_vm.disabled
                                ? _c("a", {
                                    staticClass:
                                      "el-icon-circle-plus-outline rowLineIcon",
                                    on: {
                                      click: function () {
                                        return _vm.addRate(index)
                                      },
                                    },
                                  })
                                : ind !== 0 && !_vm.disabled
                                ? _c("a", {
                                    staticClass:
                                      "el-icon-remove-outline rowLineIcon closeIcon",
                                    on: {
                                      click: function () {
                                        return _vm.delRate(index, ind)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }