var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openModal },
                    },
                    [_vm._v("添加产品")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-button", { on: { click: _vm.deleteRows } }, [
                    _vm._v("删除产品"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          ref: "xTable",
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": { background: "#E8E8E8", color: "#606266" },
            border: "",
            "max-height": "380",
          },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { prop: "materialGroupName", label: "产品名称" },
          }),
          _c("el-table-column", {
            attrs: { prop: "materialGroupCode", label: "产品编码" },
          }),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }