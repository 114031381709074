<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import { json } from 'mathjs';
import MaterialList from './components/material_list.vue';
import ActivityConfig from './components/activity_config.vue';

formCreate.component('MaterialList', MaterialList);
formCreate.component('ActivityConfig', ActivityConfig);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      // form内组件
      rule: [],
    };
  },

  mounted() {
    this.getFormRule('group_buying_Ex_warehouse_form');
  },

  methods: {
    setRule(v) {
      const item = v;
      item.props = {
        ...item.props,
      };
      if (item.field === 'dateRange') {
        item.props = {
          ...item.props,
          'unlink-panels': true,
          type: 'datetimerange',
          'default-time': ['00:00:00', '23:59:59'],
        };
      }
      if (item.field === 'productType') {
        item.options = [
          {
            value: 'materialGroup',
            label: '物料组',
          },
        ];
        item.value = 'materialGroup';
      }
      if (item.field === 'orgCode') {
        item.restful = '/mdm/baseTreeController/orgTree';
        item.restfulParams = { enableStatus: '009' };
        item.headers = { functionCode: 'select-org-up' };
        item.props = {
          ...item.props,
          options: [],
          props: {
            parent: 'parentCode', // 父级唯一标识
            value: 'code', // 唯一标识
            label: 'name', // 标签显示
            children: 'children', // 子级
          },
        };
      }
      return item;
    },
    formComplete() {
      const { row, type } = this.formConfig;
      if (type === 'view' || type === 'edit') {
        if (type === 'view') {
          this.buttons.submit = false;
        }
        const url = '/cps/v1/activity/groupBuyActivity/findDetailById';
        const params = { id: row.id };
        request.get(url, params).then((res) => {
          if (res.success) {
            const dateRange = [res.result.startTime, res.result.endTime];
            const materialList = res.result.materialGroups.map((item) => ({
              ...item,
              // materialGroupCode: item.materialCode,
              // materialGroupName: item.materialName,
            })) || [];
            const activityConfig = res.result.expressions || [];
            this.setValue({
              ...res.result,
              dateRange,
              activityConfig,
              materialList,
            });
          }
        });
      }
    },
    // 表单提交
    submit() {
      const formData = this.getFormData();
      if (!formData) {
        return false;
      }
      // if(formData.activityConfig)
      const flagData = formData.activityConfig[0];
      if (flagData.awardFlag && flagData.awardFlag === 'randomAmount') {
        const randomAmountData = JSON.parse(flagData.rewardData);
        const flagNum = randomAmountData.reduce((pre, currentData) => pre + Number(currentData.percent), 0);
        console.log(flagNum);
        if (flagNum !== 100) {
          return this.$message.info('活动配置比例和应为100！');
        }
      }
      delete formData.T1;
      delete formData.T2;
      delete formData.T3;
      const params = { ...formData };
      params.materialGroups = (params.materialList || []).map((item) => ({
        materialGroupCode: item.materialGroupCode,
        materialGroupName: item.materialGroupName,
      }));
      params.startTime = params.dateRange[0] || '';
      params.endTime = params.dateRange[1] || '';
      params.expressions = params.activityConfig.map((item) => ({
        participatorFlag: item?.participatorFlag || '',
        participatorName: item?.participatorName || '',
        barCodeType: item?.barCodeType || '',
        rewardFlag: item?.rewardFlag || '',
        rewardName: item?.rewardName || '',
        awardFlag: item?.awardFlag || '',
        awardName: item?.awardName || '',
        rewardData: item?.rewardData || '',
        // item?.awardFlag === 'randomAmount'
        //   ? JSON.stringify(item.randomAmount)
        //   : item?.fixedAmount || '',
      }));
      delete params.materialList;
      delete params.dateRange;
      delete params.activityConfig;
      params.id = this.formConfig.row.id || '';
      request
        .post('/cps/v1/activity/groupBuyActivity/create', params)
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
  },
};
</script>
