<template>
  <div>
    <el-row v-if="!disabled">
      <el-col :span="3">
        <el-button type="primary"
                   @click="openModal">添加产品</el-button>
      </el-col>
      <el-col :span="4">
        <el-button @click="deleteRows">删除产品</el-button>
      </el-col>
    </el-row>
    <el-table ref="xTable"
              :data="tableData"
              :header-cell-style="{background:'#E8E8E8',color:'#606266'}"
              style="width: 100%;margin-top:10px"
              border
              max-height="380">
      <el-table-column type="selection"
                       width="55"> </el-table-column>
      <el-table-column prop="materialGroupName"
                       label="产品名称"> </el-table-column>
      <el-table-column prop="materialGroupCode"
                       label="产品编码"> </el-table-column>
    </el-table>
    <SelectConfig ref="selectConfig"
                  @onGetSelect="onGetSelect" />
  </div>
</template>
<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  components: {
    SelectConfig,
  },
  props: {
    value: Array,
    disabled: Boolean,
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.tableData = v;
      },
    },
  },
  methods: {
    // 打开弹窗
    openModal() {
      const params = {
        functionCode: 'group_buying_productList_materialGroup',
        // parentCode: 'CRM20210825000002680',
        idKey: 'materialGroupCode',
        paramData: {},
        noReset: true,
        data: this.tableData,
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择产品回调
    onGetSelect(val) {
      console.log(val);

      if (val.length > 0) {
        this.tableData = [...val];
        this.inputTableData();
      }
    },
    // 删除选中产品
    deleteRows() {
      const deleteRows = this.$refs.xTable.selection;
      const tableData = this.tableData.reduce((previous, current) => {
        const is = deleteRows.find((item) => item.materialGroupCode === current.materialGroupCode);
        if (!is) {
          previous.push(current);
        }
        return previous;
      }, []);
      this.tableData = [...tableData];
      this.inputTableData();
    },
    // 向form抛出数据
    inputTableData() {
      this.$emit('input', this.tableData);
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
};
</script>
